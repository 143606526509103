<template>
  <div class="reset-success-container">
    <img src="/learnbread-primary-logo-removebg.png" alt="Learnbread Logo" class="logo" />
    <h1>Password Reset Successful!</h1>
    <p>Password reset for {{ uemail }} was successful! Kindly login to proceed.</p>
    <router-link to="/login">Click Here to Login</router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uemail: ''
    }
  },
  created() {
    // Call the handleGoogleCallback action when the component is created
    //this.$store.dispatch('auth/handleGoogleCallback')
    const urlSearchParams = new URLSearchParams(window.location.search)

    if (urlSearchParams.get('email')) {
      this.uemail = urlSearchParams.get('email')
    } else {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.reset-success-container {
  text-align: center;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #5bc0de; /* Bootstrap's info color */
  border-radius: 8px;
  max-width: 400px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #5bc0de; /* Bootstrap's info color */
}

p {
  margin-bottom: 20px;
}

router-link {
  color: #31708f; /* Bootstrap's info color */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

router-link:hover {
  color: #245269;
}

.logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}
</style>
